import GoogleMaps from "../shared/google-maps.js";

//#region Map Styles
// Parsing JSON string is faster than defining JSON in JavaScript.
const MAP_STYLES = JSON.parse(`[
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#B3BBBD"
      }
    ]
  },
  {
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#808080"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "visibility": "on"
      },
      {
        "weight": 2
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#DEE0E0"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]`);
//#endregion Map Styles

//#region Map Rendering
const maps = document.querySelectorAll(".google-maps");
let mapCount = maps.length;

while (mapCount--) {
  const map = maps[mapCount];
  new GoogleMaps(map, {
    apiKey: "AIzaSyAjAjeJ3mP0Y6BGZaPg8fHyIcQa-wWnI-I",
    mapOptions: {
      center: { lat: 29.7604, lng: -95.3698 },
      zoom: 4,
      mapTypeControlOptions: {
        position: "TOP_RIGHT",
      },
      styles: MAP_STYLES,
      noClear: true,
    },
    kmlLayerOptions: {
      url: (container) => container.querySelector("a").href + `&t=${ Date.now() }`,
      suppressInfoWindows: true,
    },
  });
}
//#endregion Map Rendering

//#region Placemark Interaction
let activeMarker;

document.addEventListener("google.maps.Map", ({ target: mapDiv, detail: map }) => {
  const dialog = mapDiv.querySelector(".google-maps__info");

  // Closing the dialog will also close the active marker, and let user use gestures again.
  dialog.addEventListener("close", function() {
    activeMarker.setVisible(false);
    map.setOptions({
      gestureHandling: "auto",
      zoomControl: true,
    });
  });
});

document.addEventListener("google.maps.KmlLayer.click", ({ target: mapDiv, detail }) => {
  const { map, featureData, latLng } = detail;

  let dialog = mapDiv.querySelector(".google-maps__info");
  const infoWindow = dialog.querySelector(".google-maps__infoWindow");

  let infoWindowHtml = featureData.info_window_html
    .replace(/([\d]{3}-[\d]{3}-[\d]{4})<br\/?>([A-z])/g, `$1</dd><dd>$2`)
    .replace(/:\s([\d-]+)/g, `<br /><a href="tel:$1">$1</a>`);

  infoWindow.innerHTML = `${ infoWindowHtml }`;

  const featureName = dialog.querySelector("h3");
  featureName.id = "googleMapsInfoWindowTitle";

  // Adjust CTA link with the feature's name.
  const cta = dialog.querySelector("a.button");
  if (cta) {
    const searchParams = new URLSearchParams(cta.search);
    searchParams.set("Location", featureData.name);
    cta.search = `?${ searchParams }`;
    cta.href = cta.href.replace(window.location.origin, "");
  }

  setTimeout(() => {
    dialog.show();
  });

  if (!activeMarker) {
    activeMarker = new google.maps.Marker({
      map,
      icon: {
        url: "/images/marker_highlight.svg",
      },
    });

    // Clicking on the (visible) marker will close the dialog,
    // which has an event handler to hide the marker.
    activeMarker.addListener("click", function(event) {
      if (dialog.open) {
        dialog.close();
      }
    });
  }

  activeMarker.setPosition(latLng);
  activeMarker.setVisible(true);

  // Prevent the user to zoom or pan when dialog opens.
  map.setOptions({
    gestureHandling: "none",
    zoomControl: false,
  });

});
//#endregion Placemark Interaction
