(function() {

const productsForm = document.querySelector("form.product-filters");
if (!productsForm) {
  return;
}

const productList = productsForm.parentElement.querySelector(`[typeof="ItemList"]`);
const productContainer = productList.parentElement;
const productTagList = productsForm.querySelector(".tags");
const products = productList.querySelectorAll(`[property="itemListElement"]`);
const filteredProducts = [];
let startIndex = 0, perPage = 10;

productsForm.addEventListener("reset", function() {
  productTagList.innerHTML = "";
  filterProducts();
});

productsForm.addEventListener("change", function(event) {
  const input = event.target;
  const name = input.name;

  if (input.tagName.toLowerCase() === "select") {
    input.querySelectorAll("option").forEach(function(option) {
      const tagName = option.value;
      if (option.selected) {
        addTag(productTagList, `${ name }: ${ tagName }`);
      } else {
        const tag = productTagList.querySelector(`.tag[title="${ name }: ${ tagName }"]`);
        tag && tag.remove();
      }
    });
  } else if (input.value) {
    addTag(productTagList, `${ name }: ${ input.value }`);
    input.value = "";
  }

  filterProducts();
});

// Fixes for IE not firing "change" event on hitting Enter on input.
productsForm.addEventListener("keydown", function(event) {
  const input = event.target;
  if (event.keyCode === 13 && input.tagName.toLowerCase() === "input") {
    input.dispatchEvent(new Event("change", {
      bubbles: true,
      cancelable: true,
    }));
  }
});

// Auto filter based on query string.
const searchParams = new URLSearchParams(document.location.search);
searchParams.getAll("Keyword").forEach(keyword => {
  productsForm.querySelector(`[name="Keyword"]`).value = keyword;
  toggleInput("Keyword");
});

["Category", "Subcategory", "Location"].forEach(kind => {
  searchParams.getAll(kind).forEach(query => toggleOption(kind, query) );
});

window.addEventListener("load", function() {
  filterProducts();
});

/**
 * Adds a tag into a container using specific template.
 * @param {HTMLElement} container Element that contains all the tags
 * @param {string} tagName The name of the tag to add.
 * @returns {HTMLElement} The added tag element.
 */
function addTag(container, tagName) {
  let tag = container.querySelector(`.tag[title="${ tagName }"]`);
  if (tag) {
    return tag;
  } else {
    tag = document.createElement("span");
    tag.className = "tag";
  }

  tag.title = tagName;
  tag.innerHTML = `${ tagName }
  <a href="#" aria-label="Remove filter for ${ tagName }">&times;</a>`;

  container.appendChild(tag);

  return tag;
}

// Handler for clicking to remove a tag.
document.addEventListener("click", function(event) {
  const target = event.target;
  if (target.matches("form .tag a")) {
    event.preventDefault();

    const tag = target.parentElement;
    const [kind, tagName] = tag.title.split(": ");

    if (kind === "Keyword") {
      tag.remove();
      toggleInput(kind);
      return;
    }

    toggleOption(kind, tagName);
  }
});

function toggleInput(name) {
  productsForm.querySelector(`[name="${ name }"]`).dispatchEvent(new Event("change", {
    bubbles: true,
    cancelable: true,
  }));
}

function toggleOption(name, value) {
  const option = productsForm.querySelector(`[name="${ name }"] option[value="${ value }"]`);
  option.dispatchEvent(new Event("mousedown", {
    bubbles: true,
    cancelable: true,
  }));
}

/**
 * Filters list of products by categories and subcategories.
 * @returns HTMLElement[] list of filtered products.
 */
function filterProducts() {
  // Reset the result array.
  filteredProducts.length = 0;
  const byCategories = [];

  const filters = {
    "Keyword": [],
    "Category": [],
    "Subcategory": [],
    "Location": [],
  };
  const tags = productTagList.querySelectorAll(".tag");
  tags.forEach(function(tag) {
    const [kind, value] = tag.title.split(": ");
    filters[kind].push(value);
  });

  products.forEach(function(itemListElement) {
    const textContent = itemListElement.textContent;
    itemListElement.hidden = false;
    const categories = itemListElement.getAttribute("data-Category");
    const subcategories = itemListElement.getAttribute("data-Subcategory");

    let hasCategory = filters["Category"].length === 0;
    filters["Category"].forEach(function(category) {
      if (hasCategory) {
        return;
      }

      if (categories.indexOf(category) > -1) {
        hasCategory = true;
      }
    });

    let hasSubcategory = filters["Subcategory"].length === 0;
    filters["Subcategory"].forEach(function(subcategory) {
      if (hasSubcategory) {
        return;
      }

      if (subcategories.indexOf(subcategory) > -1) {
        hasSubcategory = true;
      }
    });

    let hasLocation = filters["Location"].length === 0;
    filters["Location"].forEach(function(location) {
      if (hasLocation) {
        return;
      }

      if (itemListElement.querySelector(`.tag[title*="${ location }"`)) {
        hasLocation = true;
      }
    });

    let hasKeyword = filters["Keyword"].length === 0;
    filters["Keyword"].forEach(function(keyword) {
      if (hasKeyword) {
        return;
      }

      if (textContent.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
        hasKeyword = true;
      }
    });

    if (hasCategory) {
      byCategories.push(itemListElement);
    }

    if (hasCategory && hasSubcategory && hasLocation && hasKeyword) {
      filteredProducts.push(itemListElement);
    } else {
      itemListElement.hidden = true;
    }
  });

  let availableSubcategories = "";
  byCategories.forEach(function(itemListElement) {
    availableSubcategories += itemListElement.getAttribute("data-Subcategory");
  });

  productsForm.Subcategory.querySelectorAll("option").forEach(function(option) {
    const value = option.value;
    option.disabled = availableSubcategories.indexOf(value) === -1;
  });

  // Paginate from 0 again.
  startIndex = 0;
  paginate();

  return filteredProducts;
}

function paginate() {
  // Only shows 10 items and hide the rest.
  const count = filteredProducts.length;
  const index = startIndex + perPage;
  for (let i=0; i<count; i++) {
    filteredProducts[i].hidden = i >= index;
  }

  document.querySelector(".load-more").style.display = index >= count ? "none" : "";
}

document.addEventListener("click", function(event) {
  const target = event.target;
  if (target.matches(".load-more")) {
    event.preventDefault();

    startIndex += perPage;
    paginate();
  }
});

})();
