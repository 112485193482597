class GoogleMaps {
  constructor(el, options = {}) {
    this.el = el;
    const { apiKey } = options;

    this.load(apiKey).then(() => {
      this.init(el, options);
    });
  }

  /**
   * Load the Google Maps API javascript
   */
  load(apiKey) {
    return new Promise(resolve => {
      const callbackName = "_GoogleMapsApi.mapLoaded";

      // set a globally scoped callback if it doesn"t already exist
      if (!window._GoogleMapsApi) {
        window._GoogleMapsApi = this;
        window._GoogleMapsApi.mapLoaded = resolve;
      }

      if (typeof window.google === "undefined") {
        const script = document.createElement("script");
        script.src = `//maps.googleapis.com/maps/api/js?key=${ apiKey }&callback=${ callbackName }`;
        script.async = true;
        document.body.append(script);
      } else {
        resolve();
      }
    });
  }

  init(container, options) {
    const el = this.el;
    const { mapOptions = {}, kmlLayerOptions = {} } = options;

    // Gets KML URL first because it may be stored in an element inside the container,
    // which will be removed if we initialize the map first.
    let kmlUrl = kmlLayerOptions.url;
    if (typeof kmlUrl === "function") {
      kmlUrl = kmlLayerOptions.url = kmlUrl(container);
    }

    const { Map, ControlPosition, event, KmlLayer, KmlLayerStatus } = google.maps;

    const mapTypeControlOptions = mapOptions.mapTypeControlOptions;
    // Converts string position to Google Maps' constant.
    if (mapTypeControlOptions.position) {
      mapTypeControlOptions.position = ControlPosition[mapTypeControlOptions.position];
    }

    const map = new Map(container, mapOptions);
    trigger(el, "google.maps.Map", map);

    if (kmlUrl) {
      const kmlLayer = new KmlLayer(kmlLayerOptions);
      kmlLayer.setMap(map);

      event.addListener(kmlLayer, "status_changed", () => {
        const status = kmlLayer.getStatus();
        if (status !== KmlLayerStatus.OK) {
          console.error("Failed to load KML Layer with status:", status);
        }

        trigger(el, "google.maps.KmlLayer.status_changed");
      });

      event.addListener(kmlLayer, "click", (event) => {
        map.panTo(event.latLng);
        trigger(el, "google.maps.KmlLayer.click", {
          map,
          kmlLayer,
          ...event
        });
      });
    }
  }
}

function trigger(el, eventType, data) {
  const event = new CustomEvent(eventType, {
    bubbles: true,
    detail: data,
  });

  el.dispatchEvent(event);
}

export default GoogleMaps;
