window.addEventListener("load", () => {
  // Enhancements to let user toggle individual options without holding special key.
  // The default behaviour of multiple <select> is that clicking an option will deselect
  // all others and select the target one. In order to select multiple, users have to hold
  // special key (Ctrl, Alt, Cmd, Shift, etc...) before clicking.
  // This script changes that to make clicking a toggle instead.
  const multiSelects = document.querySelectorAll(".styled-select select[multiple]");
  multiSelects.forEach(select => {

    // Use "mousedown" because "change" will happen after the option is (un)checked,
    // so we can't really do much there.
    // Note: IE11 does not fire mousedown event from <option> ¯\_(ツ)_/¯
    select.addEventListener("mousedown", function(event) {
      const target = event.target;
      if (target.tagName.toLowerCase() === "option") {
        event.preventDefault();
        target.selected = !target.selected;
        // Dispatch change event since we prevent the default.
        select.dispatchEvent(new Event("change", {
          bubbles: true,
        }));
      }
    });

    /* Ensures all options has `label` attribute */
    const options = select.querySelectorAll("option");
    options.forEach(option => {
      if (!option.hasAttribute("label")) {
        option.setAttribute("label", option.label);
      }
    });

    /* Polyfill for browsers that do not support pseudo-elements inside <option>
    * As of 2019/10/24, Safari 13.0.2 and IE11.
    * However, only polyfill for IE11, because it will break Safari on mobile.
    */
    const pseudoContent = window.getComputedStyle(options[0], ":before").getPropertyValue("content");
    if (pseudoContent === "none") {
      options.forEach(option => {
        // Use text instead of label because IE11 does not update rendering in the same frame
        // when label changes.
        option.removeAttribute("label");
        option.textContent = `☐ ${ option.label }`;
      });

      select.addEventListener("change", function(event) {
        options.forEach(option => {
          let label = option.textContent;
          if (option.selected) {
            label = label.replace("☐", "☑");
          } else {
            label = label.replace("☑", "☐");
          }
          option.textContent = label;
        });
      });
    }

  });
}, false);
